.page-image {
	min-height: 265px;
	background: $header-bg-color url(#{$img-loc}/blender/v-202410141259/7.jpg) no-repeat 55% 45% /
		cover;
}

.page-additional {
	padding-top: 100px;
	display: flex;
	flex-direction: column;
	//padding-bottom: 1px;
}
