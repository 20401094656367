
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import "shared/functions.scss";
@import "shared/mixins.scss";
@import "shared/vars";
@import "shared/animations";

/*
* COMMON BLOCKS
*/

@import "common/templates";
@import "common/_events";
@import "common/_calendar";
@import "common/_document";


/*
* DESIGN COMPONENTS
*/

@import "blocks/additional";
@import "blocks/banners";
@import "blocks/tags";
@import "blocks/tiles";
@import "blocks/gallery";
@import "blocks/org-contacts";
@import "blocks/pagination";
@import "blocks/attachment";
@import "blocks/blender";

@import "common/_page";
