.tiles {
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
		grid-gap: 15px;
		gap: 15px;
		padding: 0 0 1rem;
		margin: 2.95em 0 2.5em;
		list-style-type: none;
	}
}

.tile {
	&__item {
		width: 100%;
		font-size: 1rem;
		font-family: $font-headings;
		font-weight: 800;
		padding: 0;
		display: flex;
		flex-direction: column;
	}

	&__link {
		flex-grow: 1;
		position: relative;
		padding: rem(30px) 1rem;
		display: block;
		text-align: center;

		@include link(&) {
			color: $tile-color;
			background-color: $tile-bg-color;
			text-decoration: none;
		}

		@include link-over(&) {
			background-color: $tile-bg-color-hover;
			color: $tile-color-hover;
		}
	}
}
